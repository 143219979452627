import React from "react";
import GradientButton from "../../components/button/gradientButton/GradientButton";
import "./ServicesStyles.scss";
const ServicesContentTwo = () => {
  return (
    <div className="ServicesContentThree">
      <div className="inner-container">
        <div className="header">Our Services are for...</div>
        <a href="/sellers">
          <GradientButton buttonText="Property Owners who want to sell" />
        </a>
        <a href="/landlords">
        <GradientButton buttonText="Landlords who want to rent out" />
        </a>
      </div>
    </div>
  );
};
export default ServicesContentTwo;
