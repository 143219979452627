import React from "react";

const LandlordContent = (props) => {
  const guideOne = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">How to Become a Successful Landlord</div>
        ) : (
          ""
        )}

        <div className="content-text">
          <div className="margin-top-40" />
          Not all tenants are created equal. Certain tenants will make your life
          as a landlord a breeze while others will make your life a living
          nightmare. Taking the time to choose the right tenant for your
          property can help protect your investment. If done correctly and
          prudently, being a landlord is a great way to bring in additional cash
          flow. However, it’s still a responsibility that requires time and
          energy as you have to be able to manage your tenants and properties.
          <div className="margin-top" />
          Becoming a landlord doesn’t need to be daunting, if proper steps are
          followed in the process.
          <div className="margin-top-40" />
          <div className="topic">Are you going to use an agency?</div>
          <div className="margin-top" />
          Agencies can assist you with many of your responsibilities for a fee.
          They can handle the process fully, or merely help you with finding
          tenants – if you choose an agency, make sure speak to a few to make
          sure you find the right one. Ask for the contact details of other
          landlords that could give you an agent reference. Reputed agencies
          with a good track record can effectively and efficiently handle the
          entire process for you. Without limiting their service to finding
          tenants, they can help you through the tenant screening (verifying
          rent, credit and employment history), preparing Move in condition
          reports and inventories etc. The right intermediary can bring lot of
          value to the table plus offer you a variety of advantages as opposed
          to dealing with tenants directly.
          <div className="margin-top-40" />
          <div className="topic bold-content">
            How to Become a Successful Landlord
          </div>
          <div className="margin-top" />
          Like in any other field, there are “Do’s and “Don’ts” in the renting
          process as well. The process comprises of main items to handle at
          different stages. We will cover them in detail in subsequent articles.
          <div className="margin-top" />
          <div className="bold-content">
            Keeping communication open and clear ensures the relationship with
            your tenants and agents runs smoothly.
          </div>
          <div className="margin-top" />
          Are you looking forward to phone calls at 3am, consistently late rent,
          drug dealing or brothel operating tenants, and costly evictions?
          <div className="margin-top" />
          Of course not.
          <div className="margin-top" />
          No one wants to be a landlord with those problems. So why do so many
          landlords find themselves in this kind of trouble?
          <div className="margin-top" />
          The answer is that most of these landlords never learned the correct
          way to rent out their houses from the beginning. Many look at the
          process of filling a vacancy like the “pregame” show – but in reality,
          getting your house rented is the game. If you learn how to rent your
          house intelligently from the beginning, you can avoid years of
          headache later.
          <div className="margin-top" />
          Chances are – you’ve heard horror stories from accidental landlords
          about costly evictions, destroyed properties, professional tenants
          from hell, and all the reasons why you should not rent your house out.
          While these stories receive the most press and attention – the fact
          is, every day thousands of landlords are renting out houses to great
          families across Sri Lanka.
          <div className="margin-top" />
          While all hassles can’t be eliminated, by following the tips, tricks,
          and techniques outlined in this guide, you will be able to minimize
          those hassles and turn your house into a profitable venture. Whatever
          your reasons are for learning how to rent your house out, this
          definitive guide is going to walk you through, step by step, how to
          rent your house with the least amount of stress and the best results.
          <div className="margin-top" />
          Before handing the keys to your house over to a complete stranger for
          care, there are decisions you need to make. In fact, the decisions you
          make now, before even searching for a tenant, will make the biggest
          impact on the success of your rental.
        </div>
      </div>
    );
  };
  const guideTwo = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Learn the Basics</div> : ""}

        <div className="content-text">
          <div className="margin-top-40" />
          Becoming a landlord doesn’t need to be daunting, take a look at some
          of the basics of becoming a landlord to understand what is involved
          before taking on any financial commitments.
          <div className="margin-top-40" />
          <div className="topic bold-content">What Makes a Good Landlord?</div>
          <div className="margin-top" />
          <div className="bold-content">Communication</div>
          The key to being an effective communicator is the ability to convey
          your needs and wants while taking into account other people’s
          perspectives. As a landlord, you will often be working with
          intermediary professionals including real estate agents and/or
          property managers as opposed to dealing with tenants directly, so
          being a clear communicator will make this process run much smoother.
          <div className="margin-top" />
          <div className="bold-content">Clarity</div>
          You need to ensure you are clear about your goals, whether its timely
          payments and arrears to be paid by the tenant, or maintenance that
          needs to be done on the property due to damages. Also, being clear
          about what needs to be communicated with your tenants in a timely
          manner will enhance the relationship and keep the property rented for
          longer periods of time.
          <div className="margin-top" />
          <div className="bold-content">Transparency</div>
          It is important that you are upfront and honest when dealing with your
          tenants and agencies. If there are issues with the property, such as
          maintenance issues or restrictions to the property that you know of,
          these need to be clearly stated, particularly when letting the
          property and finding potential tenants. Hiding faults or issues with
          the property will only come back to haunt you at a later stage so
          remember, honesty, at all times, really is the best policy.
          <div className="margin-top" />
          <div className="bold-content">Take Precautions and Get Insurance</div>
          When you plan on renting out your home, your existing homeowners
          insurance may not be sufficient. Your insurance company may perceive
          that your property is a business, and as such you will need to take
          out a business-specific policy. Check with your insurance provider to
          ensure you are getting the correct policy for your situation.
          <div className="margin-top" />
          Note: Your tenant’s personal property is not covered by your landlord
          policy, so be sure to inform your tenants to invest in their own
          insurance prior to signing the lease, to cover themselves in the event
          that something goes wrong.
          <div className="margin-top" />
          <div className="bold-content">Improvements</div>
          There is no end to the amount of improving you can do to your house
          before renting it out. The problem becomes learning when to stop. When
          is good enough, good enough? A house should be clean, empty (unless
          you are offering it “furnished”) and free from any major repairs at
          the time the tenant moves in. Be sure everything is up to code, to the
          best of your knowledge.
          <div className="margin-top" />
          It’s also important to understand the demographic that you’re
          marketing your property to. Just because YOU wouldn’t want to live in
          a lower quality residence, doesn’t mean there aren’t hundreds of
          people in that market who would be more than happy to. Your standards
          may not (and probably doesn’t) reflect the exact standards of the
          customers in your market.
          <div className="margin-top" />
          On deciding how nice to make a rental in preparing it for rent, there
          are two primary areas to consider: What kind of return you will get
          from the improvements considering how much time and cost will it save
          if the improvements were not done. Strive to offer a clean and better
          looking rental compared to the competition and rent them competitively
          as well.
          <div className="margin-top" />
          Remember, the home does not need to be as fancy as you would want it
          to be if you were living there. Look around at other rentals in your
          area and try to see what the typical quality is. Tenants will
          generally never take as good of care of your house as you do – so
          don’t spend too much time or money making unnecessary upgrades that
          will only be ignored or broken.
          <div className="margin-top" />
          <div className="bold-content">Safety & Maintenance</div>
          Does everything work? You must make sure all utilities are working.
          Tenants will check, so make sure you have tested and fixed any issues
          with the window and door locks, lighting (electric), hot water,
          water(plumbing), appliances, phone, television and internet
          connections. You should also leave copies of all relevant instruction
          manuals for your tenants.
          <div className="margin-top" />
          Make your property fire safe. Install a smoke alarm on every floor of
          your property. They are not expensive items anymore. Is there a
          burglar alarm and does it work?
          <div className="margin-top" />
          Clean the Property; Even if this is the first time you are renting out
          the unit and everything is brand new, you should still vacuum or sweep
          to remove any debris. Is the garden in good condition? Prospective
          tenants may dismiss a property immediately if the garden is poorly
          kept.
        </div>
      </div>
    );
  };
  const guideThree = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Fix the Rent & Terms</div> : ""}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic blod-content">Figure out the right rent</div>
          <div className="margin-top" />
          Have you decided on rental value? Make sure you’re pricing your
          property competitively by looking at similar properties in the same
          location.
          <div className="margin-top" />
          Also decide on the frequency and the method of collection.
          <div className="margin-top" />
          How do you go about setting rental rates in line with the current
          market? Look in the local newspaper. Pay close attention to location.
          Check the internet for local rental rates. Always base your rent rates
          on current market conditions.
          <div className="margin-top" />
          Your job is to discover what the market will allow for your house and
          attempt to get that amount, known as “Fair Market Rent.” Luckily,
          discovering what the monthly rent will bear for your house is not
          difficult; the best way to determine how much your house will rent for
          is to simply do market research.
          <div className="margin-top" />
          In business, market research means to get out there and find out what
          others in your industry are charging; learning how much to charge for
          rent is no different. Your house will generally rent for about the
          same amount as other properties are renting for that are of similar
          location, size, and condition. To determine this and do your market
          research, there are many places you can look, such as:
          <div className="margin-top" />
          Lamudi.lk ,lankapropertyweb.com, Ikman.lk, asking other local
          landlords and local newspapers to name a few.
          <div className="margin-top" />
          In a theoretical sense, the value of anything is not based on what the
          seller wants – but what the market is willing to pay.
          <div className="margin-top" />
          In the world of rentals – this means that you do not necessarily set
          the rental amount – the market does.
          <div className="margin-top-40" />
          <div className="topic blod-content">Security Deposit</div>
          <div className="margin-top" />
          Having a security deposit from your tenants is an essential step in
          protecting your property. A security deposit is a one-time refundable
          fee that you collect along with their first month’s rent.
          <div className="margin-top" />
          How much should you ask for?
          <div className="margin-top" />
          The maximum amount of security you can request depends on market
          conditions.
          <div className="margin-top" />
          It is always advisable to collect the security deposit in lump sum.
          That way, it not only proves the tenant’s financial capability but
          covers any liability costs or damages that may occur after move-in. As
          a landlord, this will provide a sense of security should anything
          happen to the property or lease agreement. At the time the tenants
          sign the lease, its best to take the first month’s rent and security
          deposit. At the very least, make sure to receive the first month’s
          rent and deposit before you hand over the keys. Insist that the
          security deposit and initial rent be paid by pay order or cash.
          Personal cheques can bounce due to insufficient funds. Plan
          accordingly, and consider only providing keys once payments have
          cleared.
          <div className="margin-top" />
          Unless the security deposit was later used to cover any costs of
          damages, the amount itself is supposed to be refundable.
          <div className="margin-top" />
          Have you set the deposit amount? Make sure the deposit is more than
          one month’s rent, this will give you some protection against damage in
          case the tenants leave without paying the last month’s rent.
          <div className="margin-top-40" />
          <div className="topic blod-content">Rent in Advance</div>
          <div className="margin-top" />
          Rent collected in advance needs to be set off against future rent. You
          may come to a mutual agreement with the tenants on this matter. It is
          always advantages for the landlord to set off the rent collected in
          advance against the last couple of months of the agreed lease term.
          You can also set off the advanced rent as a percentage of the monthly
          rent from the commencement of the lease.
          <div className="margin-top-40" />
          <div className="topic blod-content">Lease Term</div>
          <div className="margin-top" />
          It is always beneficial to the landlord to agree on longer lease
          terms. Long lease terms generally have less maintenance costs as
          compared to short term leases. Therefore, it is always advisable to go
          in for minimum of 12 months’ lease term. Longer lease terms will save
          you money from having to re-paint your property more frequently and
          from real estate commission in finding new tenants. Long term leases
          will also keep your rental occupied generating income reducing
          unoccupied months of zero revenue. Have a minimum of 12 months’ rent
          guarantee with an early termination penalty clause in the agreement
          and secure it with a solid security deposit and rent collected in
          advance.
          <div className="margin-top-40" />
          <div className="topic blod-content">Have a late policy</div>
          <div className="margin-top" />
          Make it clear from day one you will be charging a late fee for overdue
          rent. The key is to be strict with the policy. The extra income will
          help compensate for the stress of not getting rent on time.
          <div className="margin-top-40" />
          <div className="topic blod-content">Limit the occupants</div>
          <div className="margin-top" />
          More people in occupation, greater the wear and tear on your
          investment. There is a capacity that any dwelling can tolerate.
          <div className="margin-top-40" />
          <div className="topic blod-content">Pet Policy</div>
          <div className="margin-top" />
          Before placing your property in the market, you will have to decide on
          a pet policy. There are negatives to pets, such as the ability to
          cause damage to your property and may spread bad odors when animals
          are not properly groomed or cleaned, but there are also positives to
          pets, including responsible pet owners being great tenants. Most pet
          owners are affluent and can afford a better rent. Pet owners typically
          stay in a rental longer because it can be harder for them to find
          other pet friendly options. If someone is mature enough to take good
          care of an animal, there is a good chance they will treat your
          property with the same respect.
        </div>
      </div>
    );
  };
  const guideFour = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Finding a Tenant</div> : ""}

        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic bold-content">Let’s get started</div>
          <div className="margin-top" />
          Consider if this is the right time for you to be thinking about
          renewing or re-listing your upcoming vacant property. Many landlords
          aren’t sure of the timing about when to begin advertising their
          upcoming vacancies. Use “lease targeting” Schedule the majority of
          your lease end dates for times when the market is providing the most
          prospective tenant traffic.
          <div className="margin-top-40" />
          <div className="topic bold-content">Advertise Your Listing</div>
          <div className="margin-top" />
          Advertising your property properly and effectively is one of the key
          steps that ensures you get the tenants you want. Be sure to write a
          short description of the property, provide lots of photos, and a way
          for tenants to contact you. Want to maximize your exposure? Use
          multi-media marketing. Consider also posting a link to your listing
          through your Facebook and Twitter accounts. “List it and they will
          come…” I wish it were that easy. In order to get your property in
          front of the renter (many or which are millennials), you need to have
          a presence across multiple marketing channels.
          <div className="margin-top-40" />
          <div className="topic bold-content">
            Leave the job to professionals
          </div>
          <div className="margin-top" />
          A reputed agency with a good track record can effectively and
          efficiently handle the entire process for you. Without limiting their
          service to finding tenants, they can help you through the tenant
          screening (verifying rent, credit and employment history), preparing
          Move in condition reports and inventories etc. The right intermediary
          can bring lots of value to the table plus offer you a variety of
          advantages as opposed to dealing with tenants directly.
          <div className="margin-top-40" />
          <div className="topic bold-content">Keep family out of it</div>
          <div className="margin-top" />
          Renting to friends and family is a recipe for disaster. You could lose
          the money or lose the relationship. Don’t put yourself in that
          situation.
          <div className="margin-top-40" />
          <div className="topic bold-content">Showing</div>
          Right advertising should be generating plenty of leads. Let the
          interested renters see the property with your agent. Your agent can
          help the tenants visualize living in the property. Studies have shown
          that if tenants are talking about furniture placement as they tour a
          home, they are significantly more likely to want to make a deal. Your
          agent will also observe how the prospective tenants behave and what
          seems important to them. This is a really important part of the
          screening process, where red flags may easily be observed that would
          identify them as problematic tenants.
        </div>
      </div>
    );
  };
  const guideFive = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Tenant Screening Process</div>
        ) : (
          ""
        )}

        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic bold-content">You Want Quality</div>
          <div className="margin-top" />
          How can a tenant be a quality tenant? It’s quite simple. A quality
          tenant is someone who pays their rent on time and respects your
          property as if it were their own. They understand they’ve made a
          commitment to pay their rent, and while the property is their home
          during the tenancy, they also understand that at the end of the
          tenancy they must give it back, and in good condition. You can find
          out if a tenant is a quality tenant through a rigorous tenant
          screening process.
          <div className="margin-top-40" />
          <div className="topic bold-content">Be Selective</div>
          <div className="margin-top" />
          Problem tenants can cause a prickly situation. Don’t feel that you
          have to settle on a tenant just so you can rent the property quickly.
          If you have a mortgage payment coming up and the property is vacant,
          it is easy to fall victim to taking on bad tenants so you can start
          getting rent in time to make the mortgage payment. However, in the
          long-term, you’ll end up with more thorns than you avoided. Maximize
          the quality tenants you’re being exposed to so that you can be
          selective.
          <div className="margin-top" />
          <div className="bold-content">
            Most Landlords Don’t Screen Their Tenants. This is a huge mistake.
            Studies have shown that most landlords don’t know what they need to
            do to screen a tenant and they worry that any level of screening
            will turn off a prospect.So, they choose the first tenant they see,
            and do no screening. Scary. You could end up with a tenant who lives
            for free in your property for years. The eviction process is lengthy
            and costly. And some tenants can extend that too, if they know how.
            It’s easy to spot these tenants by having them complete a rental
            application and subject them to a thorough screening and a credit
            check.
          </div>
          <div className="margin-top" />
          Handover this task to be professionally handled by your agent.
          <div className="margin-top-40" />
          <div className="topic bold-content">
            Always request tenants to submit an application.{" "}
          </div>
          <div className="margin-top" />
          It should include prior rental histories for at least 5 years. This is
          because, if the applicant was a problem tenant, the current landlord
          may not be so truthful as they may just want to get the tenant off of
          their hands. A simple call or email to prior landlords can identify
          tenants that constantly paid late, caused damage or disturbed
          neighbours.
          <div className="margin-top" />
          <div className="bold-content">Call all the previous landlords. </div>
          Be sure to check if the time periods given tally with the information
          provided by the previous landlords. That might indicate if they
          conveniently “forgot” to include a landlord that they rented from.
          When talking with previous Landlords, you might want to consider
          asking the following questions:
          <div className="margin-top" />
          <ul>
            <li>How long did the tenant rent from you?</li>
            <li>What was their monthly rent?</li>
            <li>Did the tenant give proper notice when vacating?</li>
            <li>Did the tenant receive back their security deposit?</li>
            <li>Would you rent to this tenant again?</li>
          </ul>
          <div className="margin-top" />
          <div className="bold-content">
            Performing a thorough background and credit check to ensure the
            tenant is stable.
          </div>
          Choose a Tenant with Good Credit. You want to look for a tenant who is
          financially responsible. If they are responsible with paying their
          bills, they will most likely pay their rent on time and be responsible
          with your property. This is a two-step process:
          <div className="margin-top" />
          <div className="bold-content">A. Verify Their Income:</div> Ideally,
          you will want to find a tenant whose monthly income is at least three
          times the monthly rent. Ask for written confirmation of employment
          confirming the information sought directly from their HR.
          <div className="margin-top" />
          <div className="bold-content">B. Run a Credit Check:</div> See if they
          have a history of paying their bills on time. Check their income/debt
          ratio. Even if their income is three times the monthly rent, you have
          to factor in how much debt they have. For example, the rent is Rs.
          100,000/- per month. Tenant A is making Rs. 300,000 /-a month but has
          Rs. 200,000/- in debt payments every month. They most likely would not
          be able to afford to rent the house or apartment. Tenant B makes Rs.
          250,000 a month but has no debt. They could be an excellent candidate
          to pay the rent even though their income is not three times the
          monthly rent. A CRIB (Credit Information Bureau) report may reveal
          your prospective tenant’s current adverse debt exposures. You may
          request the tenant to furnish a recent CRIB repot which can be easily
          obtained on payment of a nominal specified fee.
          <div className="margin-top-40" />
          <div className="topic bold-content">
            Accepting or Denying an Applicant
          </div>
          Deciding what kind of “background” or “credit history” you will allow
          is largely dependent on your location and the strength of your market.
          If you have a lot of applicants to choose from, you can be more picky
          and only accept the highest qualified tenant.
          <div className="margin-top" />
          However, if you are struggling to get applicants, you may need to
          loosen your standards slightly in order to move someone in. People
          often lie, especially when they want to get into a new rental and
          cannot qualify. As such, it is vitally important that you verify
          everything that the tenant writes on their application.
          <div className="margin-top" />
          After receiving their background check and verifying the information
          listed on their application is correct, you will have a good idea of
          whether or not the tenant is worthy of your rental property.
          <div className="margin-top" />
          When you find an applicant that meets all your requirements, you can
          verbally let them know that they are approved. However – your job is
          not yet finished. Many times, a good applicant will be approved but
          still find another place to rent, leaving you wondering what happened
          to that applicant.
          <div className="margin-top" />
          Therefore, it is important to require a deposit to hold the vacant
          property. This deposit is non-refundable and should be due within 24
          hours of being accepted. Simply let the approved applicant know that
          you cannot hold the property indefinitely so if they want to guarantee
          their position they will need to pay the deposit within 24 hours. Some
          landlords actually require that the deposit-to-hold be paid when the
          application is filled out and the landlord simply returns the deposit
          if the tenant doesn’t qualify.
          <div className="margin-top" />
          When you collect this deposit, be sure to sign two copies of a
          “deposit to hold agreement” that states what the deposit it for and
          what the terms are. Essentially, this document will state:
          <div className="margin-top" />
          <ul></ul>
          <li>
            The applicant has until “such and such” date to sign a lease
            agreement.
          </li>
          <li>
            If not signed by that date, the deposit will be forfeited to the
            landlord.
          </li>
          <div className="margin-top" />
          Both you and the prospective tenant will receive a copy of this
          agreement, which will serve as a receipt for the applicant. Try to
          schedule the lease signing for as soon as possible, to minimize the
          amount of time the property is left vacant and costing you money.
          <div className="margin-top" />
          The most important decision you make that will determining the success
          or failure of your rental is the person you put in.
          <div className="margin-top" />
          A bad tenant can potentially cause years of stress, headache, and
          financial loss – while a great tenant can provide years of security,
          peace, and tranquility. Don’t underestimate the important of renting
          to only the best tenants. You are reading this guide because you want
          to learn how to rent your house.
          <div className="margin-top" />
          Finding the best tenants is how to make that rental enjoyable.
        </div>
      </div>
    );
  };
  const guideSix = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Tenancy Agreement</div> : ""}
        <div className="content-text">
          <div className="margin-top-40" />
          We would always recommend you seek legal or professional advice before
          entering into any kind of tenancy agreement. The terms and conditions
          of the lease must be incorporated understanding the enforceability of
          such terms. In short, your tenancy agreement must be a legally binding
          document enforceable by law. Regardless of the length of the tenancy,
          always have a written lease document. Doing so will ensure all
          expectations are clearly communicated and documented. Tenancy
          Agreements are essential for the tenant and landlord in ensuring a
          pleasant rental and leasing experience for both parties.
          <div className="margin-top-40" />
          <div className="topic blod-content">
            Components a tenancy agreement should cover
          </div>
          <div className="margin-top" />
          A tenancy agreement outlines tenant and landlord responsibilities. It
          clarifies legal rights, and helps keep both landlords and tenants
          protected. Generally, tenancy agreements cover the following; What the
          premises is, who the landlord is, who the tenant is, the amount of
          rent, method & frequency of payment, security deposit, the agreed
          length of tenancy and termination procedures, expectations and
          responsibilities, fees & payments, notary’s fees, dispute resolution,
          insurance liabilities, late rent fee and consequences incurred if
          unpaid and utilities payments etc.
          <div className="margin-top" />
          Keep communication clear to ensure you have all the correct details
          included in your tenancy agreement prior to both parties signing.
          <div className="margin-top" />
          Everyone who is financially responsible as tenants should be listed on
          the lease. And everyone should sign. You will also typically want to
          make sure you have a clause in the lease that specifies that all
          lessees are jointly and severally liable. This means each lessee is
          responsible for the full amount of the lease.
          <div className="margin-top" />
          The landlord can limit the of number of occupants in the household.
          Imposing limitations on use of property other than for the purpose
          mentioned in the agreement, prohibiting subleasing etc. will hopefully
          prevent your property from misuse or damage.
          <div className="margin-top" />
          It is also important to make the tenant responsible for regular
          maintenance ensuring your property is well taken care of. This needs
          to cover all minor repairs and maintenance work. The limit of
          liability is usually subject to a prespecified sum as a percentage of
          the monthly rent per any one item/event. Responsibility for garden
          maintenance can also be included under this area. Are you renting it
          furnished or un-furnished? Consider how you may review the price if
          you are leaving your furniture and make sure the maintenance of your
          furniture is included in the tenancy agreement.
          <div className="margin-top" />
          Generally, payment of Assessment Rates is the duty of the landlord.
          Nevertheless, the landlord may pass this obligation to the tenant, if
          the council revises the rates specifically based on an activity of the
          tenant or the nature of occupation of the premises.
        </div>
      </div>
    );
  };
  const guideSeven = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Handing Over</div> : ""}
        <div className="content-text">
          <div className="margin-top-40" />
          By this time, the rent and security deposit has been paid and lease
          has been signed. It’s now important to do one final thing before
          handing over the keys: the move-in condition report.
          <div className="margin-top-40" />
          <div className="topic bold-content">The Move-in Condition Report</div>
          <div className="margin-top" />
          The move-in condition report is a comprehensive document that the
          tenant and the landlord will sign agreeing to the condition of the
          property at the beginning of tenancy. Allow the tenant to take some
          time walking through the property and inspecting it – letting them
          take notes of the condition of each room. If there is a hole in a door
          – document it. If there is a light switch that doesn’t work – document
          it. If there is a stain on the carpet – document it. If repairs are
          necessary, both parties should discuss whether the landlord will make
          the repairs before the tenant moves in. The move-in condition report
          is designed to protect both your interests and the interests of the
          tenant when it comes time for the tenant to move out. You will later
          have to do a similar checklist upon move-out, in which you can compare
          the two checklists.
          <div className="margin-top" />
          Have a separate inventory of items handing over to the tenant together
          with their values as an addendum to the Move-in Condition report. It
          means tenants will know what they are responsible to pay in the event
          of damage caused to the items in the inventory during their custody.
          Include photos showing the current condition of the property. Photos
          will compliment the Move-in Condition report and are useful if the
          deposit is disputed at the end of the tenancy. You should make sure
          photos are dated and signed by your tenants. This will be further
          evidence in the future when the tenant moves out. Some landlords will
          even photograph and/or video record the walk through itself to
          document the tenant noting the condition of the property.
          <div className="margin-top" />
          You may wish to enlist the help of your agent, to produce this for
          you.
          <div className="margin-top-40" />
          <div className="topic bold-content">Handing over the Keys</div>
          <div className="margin-top" />
          When the move-in condition report has been signed, and the rent has
          been paid – you can now hand over the keys to the tenant to allow them
          possession.
          <div className="margin-top" />
          <div className="bold-content">
            Although the tenant may ask – never let a tenant move in things
            before signing the lease, the move-in condition report and paying
            the rent.
          </div>{" "}
          The consequences for failing to do this could be disastrous. Be sure
          that you have made it clear to the tenant the rules you expect, as
          well as the procedure for paying rent, requesting maintenance repairs,
          and other details about your house. By training your tenants, you set
          the ground rules ahead of time and ensure they are aware that you are
          not the kind of landlord that they can walk all over.
          <div className="margin-top" />
          Show new tenants around the property and make sure they know how the
          appliances work and are familiar with the arrangements for disposing
          of the household and garden rubbish.
          <div className="margin-top-40" />
          <div className="topic bold-content">
            Provide Tenant with Your Contact Information
          </div>
          <div className="margin-top" />
          You should provide the tenant with your contact information. This will
          be the method of communication which allows them to reach you if they
          have a question or complaint. Make sure they know to only contact you
          during normal business hours, 9 A.M. to 5 P.M., unless it is an
          absolute emergency.
        </div>
      </div>
    );
  };
  const guideEight = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">During the Tenancy</div> : ""}

        <div className="content-text">
          <div className="margin-top-40" />
          <div className="bold-content">Trust</div>
          You need to learn to trust the tenants and the property managers that
          look after your property. Perhaps this is your first investment
          property, something you have spent a long time saving for, or perhaps
          you own a few by now. Either way, you need to trust in real estate
          professionals and leave them to do their job, (that’s what you’re
          paying for really).
          <div className="margin-top" />
          Just like any business, trust is a crucial component of relationship
          building, not only you trusting in others, but others being able to
          trust in you as a landlord. By being transparent, clear and showing a
          little kindness now and then, you can create sustainable and
          profitable relationships that mutually benefit both parties.
          <div className="margin-top" />
          <div className="bold-content">Keep your distance</div>
          Tenants generally do not want to be dealing with their landlords on a
          regular basis. People need their space. Whether you live nearby, next
          door, or on the other side of the world, calling by phone or dropping
          by the property to check on it regularly will not only annoy them, but
          will infringe on their right to privacy.
          <div className="margin-top" />
          Do not, however, invite yourself in, or expect an invite just so you
          can view the property for faults or damages. Leave this up to the real
          estate agent or Property Manager. Although you are keeping your
          distance, be sure to still be available should they need to contact
          you by phone or email.
          <div className="margin-top" />
          <div className="bold-content">Respond promptly</div>
          There are often maintenance issues that your tenants will need you to
          respond to quickly and effectively. The method a tenant uses to raise
          a maintenance concern, alongside the average response time, should be
          outlined in the lease agreement. A broken water main or electrical
          problem needs to be responded to quickly and smoothly to keep your
          tenants renewing their lease agreements and ensure the continual
          profitability of your property.
          <div className="margin-top" />
          <div className="bold-content">Maintenance</div>
          Take care of maintenance quickly, preferably preventive maintenance.
          Regularly get major appliances serviced, so that they can function
          properly. Any task that helps prevent future repairs is one worth
          doing.
          <div className="margin-top" />
          <div className="bold-content">Repairs</div>
          Repairs? What is the difference between that and maintenance? Simply
          put, repairs are what is needed when you don’t do the maintenance.
          Repairs are typically more expensive than the maintenance and much
          more bothersome. So first, make sure to maintain the property well.
          Second, when repairs are needed, do them as quickly as possible. Minor
          repairs can grow into big issues if left unresolved. Accumulated
          damage accumulates even more damage.
          <div className="margin-top" />
          <div className="bold-content">Get organized</div>
          Keep a diary, either electronic or manual, with the dates of rental
          and mortgage repayments, council rates etc. Get a good financial
          adviser to help you with this, to ensure you are maximizing your
          repayments and cash flow from properties.
          <div className="margin-top" />
          In the event that something goes wrong, e.g. a tenant believes they
          have paid when they have not, or they are claiming they didn’t cause
          damages to the property etc., you need to ensure you have records that
          are clear and easy to find.
          <div className="margin-top" />
          Keep all documentation in organised files. All email communication can
          be organized in folders. All physical documentation such as invoices
          and receipts need to be kept in one place and labelled.
          <div className="margin-top" />
          Rent Collection If you have followed the steps so far, then you have
          screened well and have been attentive to your tenants, it is unlikely
          you will run into major issues. Consider methods that allow tenants to
          pay their rent online or through bank standing orders. Remove
          barriers. These are easy ways for tenants to schedule payments,
          reducing confusion and forgetfulness.
          <div className="margin-top" />
          <div className="bold-content">Management</div>
          Although leasing out a property is passive income and not a typical
          “day job”, regular property maintenance and management tenants will be
          required. Every three months, drop an email to ask the tenant if
          everything is satisfactory. If a tenant reports a problem, deal with
          it as soon as possible and give them regular updates about the efforts
          you are making to resolve it.
          <div className="margin-top" />
          Keep copies of any correspondence and make notes of conversations
          about any problems. The landlord should keep a copy of the repair
          request and note when and how the problem was repaired.
          <div className="margin-top" />
          If a problem does arise, make sure to notify the other party and
          discuss problems promptly. Keep lines of communication open, be open
          and honest in all your dealings, listen and be respectful to each
          others’ requirements. Make any requests in writing and keep records.
        </div>
      </div>
    );
  };
  const guideNine = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Ending the Tenancy</div> : ""}

        <div className="content-text">
          <div className="margin-top-40" />
          <div className="bold-content">Move-Out Checklists</div>
          Just as you did when the tenants moved in, provide a checklist for
          tenants when they are moving out. The checklist should list things
          that they are supposed to do (i.e. clean), as well as ask them to
          assess the state of the rental upon move-out. Go through the checklist
          with them to assess damage that they may be responsible for.
          Wear-and-tear should be the responsibility of the landlord, however.
          <div className="margin-top" />
          <div className="bold-content">Compare to Move-In Checklist</div>
          Compare the move-out checklist to the move-in checklist so that you
          can all agree who is responsible for any damage. You will be glad you
          had the tenants sign the move-in checklist should there be disputes
          about whether damage was pre-existing or not. Ensure your tenant is
          present for this and signs the document, or be able to prove you
          offered them the opportunity to be present.
          <div className="margin-top" />
          <div className="bold-content">Return the Deposit</div>
          Return the deposit in a timely manner, minus any damage. Hopefully,
          you have received a move-out checklist from the tenants, and it has
          been signed by all parties. If there was damage, it shouldn’t come as
          a surprise to the tenants. Provide an itemized list of any amounts
          withheld from the security deposit. Deduct what you need to from the
          security deposit to repair the damage. Don’t deduct any more than what
          you actually spend.
          <div className="margin-top" />
          Document As you did in the beginning, document everything. Keep all
          the checklists, receipts for any repairs, etc. These are great should
          disputes arise when the tenants claim they were entitled to the entire
          deposit back. This documentation serves a second benefit during tax
          time.
        </div>
      </div>
    );
  };
  const renderPage = () => {
    if (props.selected === 1) {
      return guideOne();
    } else if (props.selected === 2) {
      return guideTwo();
    } else if (props.selected === 3) {
      return guideThree();
    } else if (props.selected === 4) {
      return guideFour();
    } else if (props.selected === 5) {
      return guideFive();
    } else if (props.selected === 6) {
      return guideSix();
    } else if (props.selected === 7) {
      return guideSeven();
    } else if (props.selected === 8) {
      return guideEight();
    } else if (props.selected === 9) {
      return guideNine();
    } else {
      return guideOne();
    }
  };
  return <div>{renderPage()}</div>;
};
export default LandlordContent;
