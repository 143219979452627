import React from "react";
import img1 from "../../../assets/images/image-2.webp";
import img2 from "../../../assets/images/image-3.webp";
import img3 from "../../../assets/images/image-4.webp";
import img4 from "../../../assets/images/image-5.webp";
import img5 from "../../../assets/images/image-6.webp";

const BuyersContent = (props) => {
  const guideOne = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Define - Prioritize Your Needs</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          Buying a home is a wise decision because property is one of the best
          and most secure investments available. Rarely, if ever, does real
          estate decrease in value. Buying a home may have significant tax
          advantages over home renting. Home equity is another consideration.
          The equity you build as your home appreciates in value will allow you
          to borrow for expenses like a new car or for business financing. Your
          home’s equity is also the ideal down payment on the purchase of a
          second real estate property. Property is the most widely accepted
          collateral when it comes to lending.
          <div className="margin-top" />
          <div className="bold-content">Emotional Advantages</div>
          Buying a home has several emotional advantages. The independence of
          ownership means that you’ll no longer have to worry about rent
          increases and the demands of a landlord. Another advantage is a
          homeowner’s power to personalize! When you own your own home, you can
          paint and decorate as much as you want and any way you like. It’s your
          place!
          <div className="margin-top" />
          <div className="bold-content">Defining Your Housing Needs</div>
          What should your dream house look like? What are the features you must
          have at any cost? The bottom line is this: your housing needs will be
          unique. The house that you would eventually choose should depend on a
          host of factors – the size of your family, commuting time to work/
          schools, availability of and access to infrastructural facilities such
          as, parks, shopping centres and hospitals etc.
          <div className="margin-top" />
          What people seek from homes often gets tempered with age, specifically
          the number of working year’s left and budgetary constraints. This
          especially happens when other life goals such as children’s education
          or retirement compete for limited financial resources. Family size and
          composition also play a significant role in determining the type of
          house an individual would like to live in. Another important criterion
          is security; 30 years ago this was a non-issue but today it is a
          non-negotiable.
          <div className="margin-top" />
          Furthermore, with the emergence of the nuclear family, the emphasis
          seems to have shifted from the quantity to the quality of hassle-free
          services. People are increasingly opting for apartments primarily
          because they provide comprehensive services and have in-house leisure
          facilities that cater to the needs of children, adults and senior
          citizens.
          <div className="margin-top" />
          <div className="bold-content">Prioritizing Your Housing Needs</div>
          You will no doubt have a long wish list of attributes for your house.
          However, you will not find a house that meets all your requirements.
          Since this is probably the biggest investment of your life, it pays to
          devote some time to identifying your needs and prioritize them
          <div className="margin-top" />
          You have to classify your requirements as follows: essential,
          desirable and bonus. This would help you understand the trade-offs
          between various options. For instance, one home option may mean that
          your child’s school would be nearby and the office commute for your
          spouse would be very less. However, your commuting time to your
          workplace might go up substantially. Another home option might offer
          you a Jacuzzi, which may not be a priority for you, while another
          cheaper option might offer features that are essential. Prioritizing
          needs will help make decision-making easier.
          <div className="margin-top-40" />
          <div className="topic">Housing Needs Change</div>
          Ideas and priorities related to housing needs change with age and
          individual circumstances. While the above exercise will help you in
          understand your specific needs better, it is also important to think
          about how your needs might change over time. Do this as an exercise:
          ask yourself what a man’s housing needs would be when he’s in his
          20’s, the 30’s, the 40’s and in retirement. Now think of yourself 10
          years, 20 years from now and the circumstances you may find yourself
          in. Factor such things into your decision.
        </div>
      </div>
    );
  };
  const guideTwo = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Identify Costs - Determine Affordability</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="bold-content">THE COST</div>
          After you have defined your housing needs, you need to calculate the
          cost of fulfilling these. This will give you your buying budget and
          help you evaluate various housing options.
          <div className="margin-top" />
          To develop a broad understanding of the cost related to acquiring a
          home, we need to understand the major costs: down payment, closing
          costs like legal/registration and stamp duty, equated monthly
          installments (EMI) for home loan repayments, renovation expenses, fire
          insurance, mortgage protection insurance, maintenance and operation
          expenses etc. While down payment, closing costs, renovation expenses
          and insurance primarily determine the affordability of the house, you
          need to be aware of maintenance and operation expenses and the loan
          repayment costs, since they would impact your future finances.
        </div>
      </div>
    );
  };
  const guideThree = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Negotiate the Right Loan</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="bold-content">GET APPROVAL BEFORE HOUSE HUNTING</div>
          Most banks are lazy to offer this service. Some bank officers still
          have the mindset that they are doing you a favour by giving a mortgage
          loan. However, we have seen, in the recent times, few banks have
          realized the importance of this service to their customers and process
          the credit approval prior to selection of a property. Pre-approval
          from a lender is based on your actual income, debt and credit history.
          Getting pre-approval on a mortgage will save you the hassle of looking
          at properties you can’t afford. You’ll also be in a better position to
          make a serious offer when you do find the right house. This will also
          greatly reduce the closing time making your offer attractive to the
          seller in a competitive market.
          <div className="margin-top" />
          <div className="bold-content">LOAN TENURE</div>
          Loan tenures usually range from 5-20 years and you need to choose
          yours depending on factors like number of working years left,
          repayment ability and cash flow needs. The longer your tenure, the
          greater your total interest costs. Of course, a longer tenure does
          help in reducing the equated monthly installments (EMIs). Experts
          advice borrowers to aim at fully repaying the loan before they stop
          working so that the retirement income is not dented by repayment
          obligations. Therefore, the lesser number of years you have to retire,
          the shorter should be your loan tenure.
          <div className="margin-top" />
          INTEREST RATE CALCULATIONS A very important factor that differentiates
          one home loan from the next is the different ways in which interest
          rates are calculated and charged, for example the distinction between
          variable (floating) and fixed interest rates. You have to be mindful
          of these subtleties.
          <div className="margin-top-40" />
          <div className="topic">
            Choose Carefully Between a fixed or variable rate mortgage
          </div>
          <div className="margin-top" />
          Fixed rate loans, as the name indicates, are those whose rates are
          fixed for the entire tenure of the loan. In the case of floating rate
          loans, the rate is benchmarked to a retail lending rate of the lending
          institution and moves in tandem with it. Thus, the total interest you
          pay out during the tenure is dependent on the way interest rates move
          during the term. The rate is reviewed periodically (usually every six
          months) and re-set in relation to prevailing market conditions. A
          combination of both these elements are also on offer. Meaning, part of
          the loan tenure is at fixed rate and the balance term is at variable
          rate.
          <div className="margin-top" />
          You should opt for a fixed rate loan only if interest rates in the
          economy are either rising or have stabilized. They are also useful if
          the tenure is small, such as 5 years. Conversely, if interest rates
          are falling either because of economic downturn or governmental
          intervention, and are likely to stabilise at a lower level by the end
          of your tenure, then opt for a variable (floating) rate.
          <div className="margin-top" />
          All home loan providers claim that their home loans are the best for
          you. But how do you find out what is best for you? Get the answers to
          the following question from the lending institutions during shopping
          around.
          <div className="margin-top" />
          1. Will your entire gross income be considered? Will the income of the
          spouse, parents and other family members be considered?
          <div className="margin-top" />
          2. What is the rate of interest of the loan?
          <div className="margin-top" />
          3. What is the nature of interest rate (floating or fixed rate) and
          how is it computed?
          <div className="margin-top" />
          4. Will the interest be charged in advance or after the end of the
          month?
          <div className="margin-top" />
          5. What is the loan tenure?
          <div className="margin-top" />
          6. Will the lender levy commitment charges?
          <div className="margin-top" />
          7. What security or collateral will be sought?
          <div className="margin-top" />
          8. What percentage of the forced sale value of the property is granted
          as the loan. (Please be mindful. Some banks will promise you 90% of
          the forced sale value and later come up with disappointing valuations
          as a method of controlling credit)
          <div className="margin-top" />
          9. What are the processing charges and what is included in them?
          <div className="margin-top" />
          10. Would there be separate charges for legal and technical
          examination of the property?
          <div className="margin-top" />
          11. Would there be a penalty for partial or full pre-settlement of
          loan?
          <div className="margin-top" />
          12. Will the loan amount include closing costs like stamp duties and
          legal fees?
        </div>
      </div>
    );
  };
  const guideFour = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Retain Your Real Estate Professional</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic">Do i need a real estate professional?</div>
          <div className="margin-top" />
          Many potential home buyers may wonder if they really need the help of
          a REAL ESTATE PROFESSIONAL? At some level we all think we know best
          about anything we have to decide on. But as professionals of one kind
          or another we know that we know more about our subject than
          non-professionals. Professionals make things painless. They help you
          navigate complex processes without hassle. A committed REAL ESTATE
          PROFESSIONAL will make purchasing a house simple and painless.
          <div className="margin-top-40" />
          <div className="topic">Buyer Representation</div>
          <div className="margin-top" />
          The buyer’s Real Estate Professional works for the buyer and is
          morally obligated to look after buyer’s best interests throughout the
          entire real estate transaction. This system has been first introduced
          in the USA and Canada in early 1990’s. However, this is not practiced
          locally mainly due to real estate practices remain unregulated in Sri
          Lanka to date. In developed countries, the buyer’s Real Estate
          Professional owes full fiduciary responsibilities, duties and loyalty
          to the real estate buyer.
          <div className="margin-top-40" />
          <div className="topic">Who Pays The Broker?</div>
          <div className="margin-top" />
          Sri Lanka’s standard commission rate in real estate transactions is 3%
          of the sale value and this is normally paid by the seller. In order to
          establish the said relationship between the buyer and the Real Estate
          Professional, it is best for the buyer to agree to meet the commission
          involved in the transaction. If you disagree to meet the Real Estate
          Professionals’ commission, he could choose to show you only a limited
          number of properties listed with him by a fewer number of sellers.
          Your interests will naturally be overlooked in such a transaction.
          <div className="margin-top" />
          When the buyer agrees to pay the commission, he can prevail upon the
          Real Estate Professional to make accessible all types of properties
          for sale including “No Brokers” and “For Sale by Owner” properties as
          well. The buyer must make it a point to disclose the nature of the
          commission understanding that exists with his Real Estate Professional
          to the seller since it is unethical for real estate intermediaries to
          earn commissions from both the buyer and the seller. This will avoid
          possible undue advantage possibly taken by some real estate
          intermediaries.
          <div className="margin-top" />
          Your Real Estate Professional should be knowledgeable, experienced and
          be thoroughly conversant with:
          <div className="margin-top" />
          1. Market conditions.
          <br /> 2. Procedures and documentation.
          <br /> 3. Financing and mortgage related matters.
          <br /> 4. What is negotiable in a contract and what is not.
          <br /> 5. Negotiating the best deal for you.
          <br /> 6. Preparing offer/counter-offers.
          <br /> 7. Facilitating home inspections.
          <br /> 8. An expert Real Estate Professional will also understand the
          complexities and importance of:
          <br /> i. Titles
          <br /> ii. Taxes
          <br /> iii. Surveys
          <br /> iv. Local authority and UDA regulations Insurance
          <div className="margin-top-40" />
          <div className="topic">here are some smart questions to ask:</div>
          <div className="margin-top" />
          Before deciding on someone to represent your interests in the home
          buying process, here are some smart questions to ask:
          <div className="margin-top" />
          <div className="bold-content">
            1. How long have you been in the business?
          </div>{" "}
          Those in the business longer bring more practical experience to the
          table.
          <div className="margin-top" />
          <div className="bold-content">
            2. How will you find a home that meets my needs?
          </div>{" "}
          Specifically, how will you find the listed properties for sale and
          match them with my requirements?
          <div className="margin-top" />
          <div className="bold-content">
            3. How many sales have you completed during the last 6 months and
            will you provide those clients as references?
          </div>{" "}
          Ask if any of the references are related to them. Ask if you can call
          their references with additional questions.
          <div className="margin-top" />
          <div className="bold-content">
            4. What separates you from your competition?
          </div>{" "}
          Key phrases to listen for: assertive, available by phone or e-mail,
          analytical, professional, reliable and endowed with good negotiating
          skills.
          <div className="margin-top" />
          <div className="bold-content">5. How much do you charge?</div> It is
          important to know your obligations beforehand.
          <div className="margin-top" />
          <div className="bold-content">
            6. What haven’t I asked you that I need to know?
          </div>{" "}
          Pay close attention to how the real estate professional answers this
          question, because there is something you need to know always.
          <div className="margin-top" />
          Even though the internet and newspaper classifieds provide buyers with
          easy access to local home listings, buyers are better off using a
          professional real estate agent. With all the negotiations and house
          hunting involved, you’ll need someone to coordinate the entire
          process. Look for an agent, who will have your best interests at heart
          and can help you with the negotiation process.
        </div>
      </div>
    );
  };
  const guideFive = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Spotting Your Dream Home</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic">
            Owning your own home is a universal and timeless dream
          </div>
          <div className="margin-top" />
          You have now identified your needs and the budget, acquainted yourself
          with the home buying process and organized your financial resources.
          <div className="margin-top" />
          You are now ready for the house-hunt. You can start your home search
          process by colleting information about housing options from various
          sources like internet, newspaper classifieds and your Real Estate
          Professional. Sooner or later you will come face to face with the
          following reality: over-the-phone descriptions given by an owner or
          broker is nothing more than sweet phrases to persuade you to visit.
          <div className="margin-top" />
          <div className="bold-content">Do not get carried away.</div>
          <div className="margin-top" />
          Get your checklist out and start asking questions. Be sure that the
          Real Estate Professional you work with has the knowledge and empathy
          to relate your requirements to a property. Keep in mind that the
          ‘Dream Home’ that you discover can often be unaffordable. Don’t give
          up. Stick to the basics. You will find the ‘perfect fit’ in terms of
          your requirement(s) and budget.
          <div className="margin-top" />
          <div className="bold-content">Criteria for buying</div>
          When looking for a home, you should seek out:
          <div className="margin-top" />
          1. A suitable location
          <br />
          2. A home that fits your needs
          <br />
          3. The best possible terms
          <br />
          4. The best possible price
          <br />
          5. All of the above in the most convenient way possible
        </div>
      </div>
    );
  };
  const guideSix = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Negotiate the Best Deal</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic"> Do Your Homework Before Placing An Offer</div>
          <div className="margin-top" />
          Your opening offer should be based on the sales trends of similar
          homes in the neighborhood. Consider sales of similar homes in the last
          three months before you proceed.
          <div className="margin-top" />
          There are two logical systems or theories to base your offer. Either
          you could make a comparative offer based on the facts and information
          collected during your home buying process or you could simply make an
          offer based on your affordability. In the first instance your offer is
          scientific. In the second instance you expect luck to work for you to
          acquire a home that is of a far greater value than your offer. In
          either situation make your offer 5 -10% less than what you are really
          ready to pay for the property. Keep that margin to negotiate.
          <div className="margin-top" />
          The most foolish but commonly adopted move by many buyers in the
          market today is to degrade and condemn the property and the
          surrounding you want to call home tomorrow but still make offers.
          Another foolish approach is to inquire as to what offers others have
          made. Do you really believe you can get the true picture from sellers?
          Every unwise move in the process will reduce your chances of buying
          what you really love to own. Be smarter than the next person. Be
          cautious. Be realistic.
          <div className="margin-top-40" />
          <div className="bold-content">Consult Professionals</div>
          Almost all offers to buy are conditional on a satisfactory title
          clearance and resurvey. This ensures you are not buying someone else’s
          problems. You should hire your own surveyor and notary with experience
          in the area where you are buying.
          <div className="margin-top" />
          Obtaining services of a structural/civil engineer and an architect too
          would be useful and indeed necessary if you wish to effect any
          structural/ architectural changes to the property under consideration.
          Their job will be to alert you to potential problems relating to the
          property. A little money spent in consulting professionals can save
          you substantially in the long run. If you wish to obtain
          professionals’ opinion on anything, it is best such consultation and
          inspections are arranged prior to offer and price negotiation.
          <div className="margin-top-40" />
          <div className="topic">The Art of Counter-Offers and Negotiation</div>
          <div className="margin-top" />
          Be prepared to compromise. “Win-win” doesn’t mean both the buyer and
          the seller will get everything they want. It means both sides will win
          some and give some. Rather than approaching negotiations from an
          adversarial winner-take-all perspective, focus on your top priorities
          and don’t let your emotions overrule your better judgment. A
          successful negotiation is one that leaves both you and the seller
          feeling satisfied with the outcome. This is a highly emotional time,
          so be sure to regularly “check your head”. Ask yourself “How important
          is this particular detail to me? Am I willing to jeopardize the buy
          over this?”
          <div className="margin-top" />
          Leave the talking to an expert and a seasoned negotiator. Your Real
          Estate Professional will help you every step of the way. Successful
          negotiating is more than a matter of luck or natural talent. It also
          encompasses the learned ability to use certain skills and techniques
          to bring about those coveted win-win results.
          <div className="margin-top" />
          Happy negotiating and best of luck!
        </div>
      </div>
    );
  };
  const guideSeven = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Obtain Title Clearance</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          In the end it is up to you to safeguard your interests. When you keep
          this in mind during the home buying process you reduce the chances of
          stumbling while making one of your life’s most important decisions.
          <div className="margin-top" />
          Do not buy on trust. Just because the seller has been introduced by a
          family member or a common acquaintance doesn’t mean that you should
          throw caution to the winds. Stick to the plan. Investigate the owner’s
          title to the property.
          <div className="margin-top" />
          Recommendation of Title for purchase is generally obtained through
          your attorney. It will be in your own interest to obtain this
          recommendation from your attorney in writing. Make sure that due
          diligence is observed by your legal professional in the title
          recommendation process.
          <div className="margin-top" />
          <div className="bold-content">
            1. Investigate seller’s title to the property.
          </div>
          This could be done by examining the pedigree of the title to the
          property. Generally the title must be clear of encumbrances for the
          last 30 years preceding your transaction. The seller should provide
          copies of all plans and deeds and court rulings pertaining to the
          property for the last 30 years. Documents provided by the seller must
          tally with the title extracts obtained from the area land registry.
          <div className="margin-top" />
          <div className="bold-content">2. Mortgage status.</div>
          If there is a current mortgage pending, the status of the mortgage
          will have to be called from the lender indicating the loan and capital
          outstanding as at date together with the closing costs. All previous
          mortgages must be duly cancelled and registered.
          <div className="margin-top" />
          <span className="bold-content">
            3. Call for the latest local authority documents
          </span>{" "}
          such as Street /Building line Certificate, Ownership/Non-vesting
          Certificate, Assessment rate paid receipts.
          <div className="margin-top" />
          <span className="bold-content">
            4. Ensure all approvals are in order;
          </span>{" "}
          Subdivision Approval, Building Plan and Permit, Certificate of
          Conformity.
          <div className="margin-top" />
          <div className="bold-content">
            5. Ensure that the seller is in possession of all original
            documents.
          </div>
          This is essential since the title is generally approved based on the
          photocopies provided by the seller.
        </div>
      </div>
    );
  };
  const guideEight = () => {
    return (
      <div className="content">
        {!props.mobile ? (
          <div className="topic">Completing the Transaction</div>
        ) : (
          ""
        )}
        <div className="content-text">
          <div className="margin-top-40" />
          You are now on the final stretch of your home buying process. At this
          stage, the final item on your buyer’s agenda should be to complete all
          the legal formalities pertaining to the transaction.
          <div className="margin-top" />
          Here is a closing checklist that will guide you trough the final
          steps:
          <div className="margin-top" />
          <span className="bold-content">1. Set up a closing date.</span>{" "}
          Consult the seller, the bank and your attorney (Notary Public)
          <div className="margin-top" />
          <span className="bold-content">
            2. Ensure the draft deed of conveyance is approved
          </span>{" "}
          by the seller at least one day prior to the closing date.
          <div className="margin-top" />
          <span className="bold-content">
            3. Obtain opinion of value for determination of Stamp Duty
          </span>{" "}
          -Your final deed of conveyance must be submitted to the Inland Revenue
          Department of the relevant Provincial Council for determination of
          market value for the computation of Stamp Duty payable to the
          Provincial Council.
          <div className="margin-top" />
          <span className="bold-content">
            4. Obtain loan termination cost /balance confirmation
          </span>{" "}
          –If you are expected to settle a current mortgage at the point of
          sale.
          <div className="margin-top" />
          <span className="bold-content">
            5. Set up the venue and the time.
          </span>{" "}
          Today, most transactions take place in banks. Most banks willingly
          facilitate these kinds of transactions by providing a special place,
          staff and cash counting machines etc. It is prudent to take this
          option especially if the full consideration or a part thereof is
          payable by cash. Banks provide the best and most secure environment
          for the purpose.
          <div className="margin-top" />
          <span className="bold-content">
            6. Make prior arrangement with the bank
          </span>{" "}
          if a large amount of money is to be withdrawn. The bank will have your
          cash ready and disburse to you in private.
          <div className="margin-top" />
          <span className="bold-content">
            7. The seller has the right to know the authenticity of the draft
          </span>{" "}
          by calling the issuing bank before placing his signature on the deed
          of conveyance if the consideration is fully or partly paid by a bank
          draft (Manager’s cheque).
          <div className="margin-top" />
          <span className="bold-content">
            8. The seller is expected to carry a large amount of cash with
            him/her
          </span>{" "}
          if the consideration is fully or partly paid in cash and the
          arrangements must be discussed and mutually agreed prior to the
          closing date.
          <div className="margin-top" />
          <span className="bold-content">
            9. Witnesses Must be known to the Notary attesting the deed of
            transfer.
          </span>{" "}
          It is best if you could arrange two witnesses who can identify the
          seller as well as known to the notary.
          <div className="margin-top" />
          <span className="bold-content">10. Keep cash ready</span> for the
          legal fees and stamp duty.
          <div className="margin-top" />
          <span className="bold-content">
            11. Ensure that your lending institution is ready with the mortgage
            bond and the necessary bank drafts for loan disbursement.
          </span>{" "}
          (if applicable)
          <div className="margin-top" />
          <span className="bold-content">
            12. Obtain an acknowledgement from the seller
          </span>{" "}
          for the consideration paid.
          <div className="margin-top" />
          <span className="bold-content">
            13. Obtain proof of identity
          </span>{" "}
          (photocopy of NIC or Passport) from all signatories to the sale,
          including witnesses.
          <div className="margin-top" />
          <span className="bold-content">
            14. Take over originals of all previous deeds and documents.
          </span>
          <div className="margin-top" />
          <span className="bold-content">15. Take over keys </span>and vacant
          possession of the home.
        </div>
      </div>
    );
  };
  const guideNine = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Know Your Documents</div> : ""}
        <div className="content-text">
          <div className="margin-top-40" />
          Abstract of Title This constitutes a brief history of the transfers of
          a piece of land, including all claims that could be made against it.
          <div className="margin-top" />
          An Abstract of Title is distinguished from an Opinion of Title. While
          the former states that all of the public record documents concerning
          the property in question are contained therein, the latter states the
          professional judgment of the person giving the opinion as to the
          vesting of the title and other matters concerning the status of the
          chain of title. Giving of an opinion of title is a practice of law,
          thus making it unprofessional for a non-attorney to do so.
          <div className="margin-top" />
          <div className="topic">As-Built Drawings</div>
          Drawings prepared after construction that describe the actual
          construction of a project.
          <div className="margin-top" />
          <div className="topic">Assessment Notice</div>
          Assessment Notice indicates the quarterly assessment rates payable to
          the local authority. Assessments rates are subject to revision
          periodically and charged for providing public amenities such as
          garbage disposal, maintenance of roads, drains and drainage, parks,
          reading rooms etc.
          <div className="margin-top" />
          <div className="topic">Blocking-Out Plan</div>
          A plan showing a subdivision with all dimensions, covenants,
          easements, etc. The subdivision survey plan, which legally must be
          prepared by a Licensed Surveyor, finalises the area and dimensions of
          the proposed lots. All subdivisions will have to be approved by the
          planning committee of the local authority in the area or the UDA. The
          applicant needs to send the subdivision survey plan to the planning
          division of the local authority/UDA together with the perfected
          application counter signed by the Surveyor. Upon payment of the
          specified fees by the applicant, a technical officer will make a site
          visit prior to forwarding the plan to the planning committee for
          approval.
          <div className="margin-top" />
          <div className="topic">Building Plan & Building Permit</div>
          This refers to the view of a building floor, looking down from above,
          showing its horizontal elements, such as, walls, doors, windows,
          cabinetry, etc., prepared by a qualified professional and submitted to
          the local authority for approval in three copies. Once approved, it is
          valid for one year and is renewable for two more years. In case the
          building remain incomplete after 3 years from the date of first
          approval, a fresh building plan needs to be approved following the
          same procedure. “The Building Permit” specifies the other terms and
          conditions to be adhered during construction.
          <div className="margin-top" />
          <div className="topic">Certificate of Conformity (CoC)</div>
          A certificate issued by the local authority confirming that the
          construction has been completed in conformity with the regulations in
          place and in accordance with the approved Building Plan and the
          Building Permit.
          <div className="margin-top" />
          <div className="topic">Land Registry Extract</div>
          Obtaining a Land Registry Extract has become a crucial part during the
          preparation process for the purchase of a real estate, since the land
          registry extract enjoys public faith. Due diligence requires that the
          buyer knows the status of the land registry. Not knowing it can also
          result in conflicts on the property. The extract must be stamped by
          the Land Registrar in order to be verified. You can apply for the Land
          Registry Extract of title of a property you are interested in by
          filling up an application and paying the specified fee. Usually a land
          registry extract can be obtained within a week.
          <div className="margin-top" />
          <div className="topic">Non-Vesting Certificate</div>
          The certificate of confirmation issued by the local authority that all
          assessments rates have been paid up to date. This also confirms that
          no vesting order has been served on the property for default in
          assessment rates.
          <div className="margin-top" />
          <div className="topic">Ownership Certificate</div>
          A certificate of title is a local authority-issued document that
          identifies the owner or owners of personal or real property. A
          certificate of title provides documentary evidence of the right of
          ownership. It is important for a real property owner to get his/her
          ownership registered at the council after acquiring ownership of a
          property through a deed transfer. A specified form called the AT form
          (Abstract of Title) to be filed through a notary requesting to
          register the new ownership in the council books.
          <div className="margin-top" />
          <div className="topic">Power of attorney</div>
          A power of attorney is a document that allows you to appoint a person
          or organization to handle your affairs while you’re unavailable or
          unable to do so. The person or organization you appoint is referred to
          as an “Attorney”
          <div className="margin-top" />
          A General Power of Attorney is very broad and provides extensive
          powers to the person or organization you appoint as your “Attorney”.
          These powers usually include:
          <div className="margin-top" />
          <ul class="a">
            <li>Handling banking transactions</li>
            <li>Entering safety deposit boxes</li>
            <li>Buying and selling property</li>

            <li>Settling claims</li>
            <li>Entering into contracts</li>
            <li>Exercising stock rights</li>
            <li>Managing real estate</li>
            <li>Filing tax returns</li>
            <li>
              Handling matters related to government benefits you also have the
              option to grant the following additional powers to your
              “Attorney”:
            </li>
            <li>Maintaining and operating business interests</li>
            <li>Employing professional assistance</li>
            <li>Making gifts</li>
          </ul>
          <div className="margin-top" />
          A general power of attorney usually allows your “Attorney” to handle
          all of your affairs during a period of time when you are unable to do
          so; for example, when you are traveling out of the country or when you
          are physically unable to handle your affairs.
          <div className="margin-top" />
          A Special Power of Attorney restricts your “Attorney” to act on your
          behalf in a specific purpose only. Nowadays, most property buyers
          insist on a Special Power of Attorney specifying the schedule of the
          real property as described in the owner’s deed, whenever the seller
          cannot be physically present at the transaction.
          <div className="margin-top" />
          It is obligatory for the “Attorney” to provide an affidavit confirming
          that the Power of Attorney has not been revoked or cancelled and the
          principal/grantor is still living whenever the Power of Attorney is
          used.
          <div className="margin-top" />
          <div className="topic">Sale and Purchase Agreement</div>
          The Sale & Purchase Agreement is an instrument used mainly to
          accommodate three situations (a) To allow time for the purchaser to
          arrange finances (b) To allow time for the seller to handover vacant
          possession of the property. (c) To accommodate both aforesaid
          situations. There are covenants for both parties to fulfil in a Sale &
          Purchase Agreement. There are exit clauses for both parties and
          penalties in the event of default. Therefore, a Sale and Purchase
          agreement is not a simple document where one party expresses the
          willingness to buy and the other party expresses the willingness to
          sell within a set timeframe. It is an agreement of commitment where
          the purchaser places a price tag for his confidence to raise the
          finances to complete the purchase and seller undertakes to fulfil
          certain obligations and eventually to transfer the property in vacant
          possession. Either party falling short of their respective commitments
          have consequences. Generally, it is an accepted norm for the seller’s
          notary to draft and execute the Sale and Purchase Agreement where both
          parties bear the cost equally.
          <div className="margin-top" />
          <div className="topic">Street/Building Line Certificate</div>
          This certificate confirms whether the property is affected by a
          proposed road widening or any other reservations. The local authority
          generally issues a photocopy of the survey plan indicating the
          approximate position of the Street Line marked in red in case the
          property is affected by a Street Line. The Building Line is generally
          measured from the center of the road depending on the width of the
          roads the property is bounded by. The main purpose of imposing a
          Building Line is to maintain a reasonable distance between buildings
          to ensure privacy and sanitation of occupants.
          <div className="margin-top" />
          <div className="topic">Survey Plan</div>
          This refers to a plan prepared by a Licensed Surveyor from field
          survey data and previous survey plan data according to standards and
          directions of the Surveyor-General. It is an assessment of property
          lines to determine the exact extent of land that a homeowner owns.
          Surveys show any easements or encroachments on a property that is
          noted on the title of the property. It’s important to get a property
          surveyed after an offer is made to make sure any issues with easements
          or encroachments are documented on the title and resolved before
          closing. Make sure that the property you are buying has been approved
          by the local authority for its purpose of use such as residential,
          commercial or mixed development.
          <div className="margin-top" />
          <div className="topic">Tax Receipt</div>
          The proof of payment of quarterly assessment rates as indicated in the
          Assessment Notice.
          <div className="margin-top" />
          <div className="topic">Title Deed</div>
          Title deeds are documents showing ownership as well as rights,
          obligations or mortgages on the property. Compulsory registration is
          required in the Land Registry of the relevant area for all properties
          mortgaged or transferred. The details of rights, obligations, and
          covenants referred to in deeds will be transferred to the register,
          describing the property ownership.
          <div className="margin-top" />
          <div className="topic">Title Report</div>
          A written analysis of the status of title to real property, including
          a property description, names of titleholders and how title is held,
          encumbrances (mortgages, liens, deeds of trusts, recorded judgments).
          The history of the title is called an “abstract.” Generally a title
          report is prepared by an attorney who has substantial experience in
          notarial practice or in a legal firm.
          <div className="margin-top" />
          <div className="topic">Valuation</div>
          Real estate appraisal, property valuation or land valuation is the
          practice of developing an opinion of the value of real property,
          usually its Market Value. The need for appraisals arises from the
          heterogeneous nature of property as an investment class: no two
          properties are identical, and all properties differ from each other in
          their location – which is one of the most important determinants of
          their value. The absence of a market-based pricing mechanism
          determines the need for an expert appraisal/valuation of real
          estate/property.
          <div className="margin-top" />A real estate appraisal is generally
          performed by a licensed or certified appraiser (in many countries
          known as a Property Valuer or Land Valuer. If the appraiser’s opinion
          is based on Market Value, then it must also be based on the Highest
          and Best Use of the real property. In mortgage valuations, lending
          institutions base their lending criteria on the Forced Sale Value. The
          appraisal is most often reported on a standardized format. Appraisals
          of more complex property (e.g. — income producing, raw land) are
          usually reported in a narrative appraisal report.
        </div>
      </div>
    );
  };
  const guideTen = () => {
    return (
      <div className="content">
        {!props.mobile ? <div className="topic">Stamp Duty</div> : ""}

        <div className="content-text">
          <div className="margin-top-40" />
          <div className="topic">Stamp Duty on Purchasing a Property</div>
          <div className="margin-top" />
          <div className="bold-content">
            Stamp duty payable to the relevant Provincial Councils under Stamp
            Duty Act No.43 of 1982 – Extraordinary Gazette No.1439/3 dated
            03.04.2006
          </div>
          <div className="margin-top" />
          <span className="bold-content">[A] Deed of Transfer</span> (except
          Gift and Conveyance by Executor, Administrator or Trustee to a
          Beneficiary or by an Order of Court in matrimonial proceedings)
          <img alt="" src={img1} />
          <div className="margin-top" />
          <div className="bold-content">[B] Deed of Gift</div>
          <img alt="" src={img2} />
          <div className="margin-top" />
          <div className="bold-content">[C] Deed of Exchange</div>
          <img alt="" src={img3} />
          <div className="margin-top" />
          Stamp Duty payable to the Central Government under Stamp Duty (Special
          Provisions) Act No.12 of 2006 – Extraordinary Gazette No.1465/19 dated
          05.10.2006
          <div className="margin-top" />
          <div className="bold-content">[D] Indenture of Lease</div>
          <img alt="" src={img4} />
          <div className="margin-top" />
          <div className="bold-content">[E] Mortgage Bond</div>
          <img alt="" src={img5} />
          <div className="margin-top" />
          where the mortgage is any commercial or specialized bank licensed by
          the Monetory Board under Banking Act No.30 of 1988, any finance
          company registered under the Finance Company Act No.78 of 1988, any
          institution registered under the Co-operative Societies Law No.5 of
          1972, any public corporation, any provident fund approved by the
          Commissioner General of Inland Revenue for the purposes of the Inland
          Revenue Act No.4 of 1963 or No.28 of 1979 or No.38 of 2000 or No.10 of
          2006 or any other institution approved by the Minister in charge of
          the subject of Housing.
        </div>
      </div>
    );
  };
  const renderPage = () => {
    if (props.selected === 1) {
      return guideOne();
    } else if (props.selected === 2) {
      return guideTwo();
    } else if (props.selected === 3) {
      return guideThree();
    } else if (props.selected === 4) {
      return guideFour();
    } else if (props.selected === 5) {
      return guideFive();
    } else if (props.selected === 6) {
      return guideSix();
    } else if (props.selected === 7) {
      return guideSeven();
    } else if (props.selected === 8) {
      return guideEight();
    } else if (props.selected === 9) {
      return guideNine();
    } else if (props.selected === 10) {
      return guideTen();
    } else {
      return guideOne();
    }
  };
  return <div>{renderPage()}</div>;
};
export default BuyersContent;
