const developemnt = {
  apiEndpoints: {
    protocol: "https://",
    server: "",
  },
  profileBook: "someLink",
  emailJs: {
    SERVICE_ID: "service_ke2vvpo",
    TEMPLATE_ID: "template_s9bpvfm",
    PUBLIC_KEY: "QZDT3_Y3w9txw3mXT"
  }
};
let basicEndpoint = developemnt
export default basicEndpoint